import React from 'react'
import {
	BrowserRouter as Router,
	Routes,
	Route,
	useLocation,
} from 'react-router-dom'
import './App.css'
import './fonts.css'
import Navbar from './components/NavBar'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import Users from './pages/Users'
import ComingSoon from './pages/ComingSoon'
import PrivateRoute from './components/PrivateRoute'

const App = () => {
	const location = useLocation()
	const showNavbar = location.pathname !== '/'

	return (
		<div className="font-baloobhaijaan2">
			{showNavbar && <Navbar />}
			<Routes>
				<Route path="/" element={<Login />} />
				<Route
					path="/dashboard"
					element={<PrivateRoute element={<Dashboard />} />}
				/>
				<Route path="/users" element={<PrivateRoute element={<Users />} />} />
				<Route path="*" element={<ComingSoon />} />
			</Routes>

			<style>
				{`
        /* Style untuk scrollbar */
        ::-webkit-scrollbar {
          width: 8px; /* Lebar scrollbar */
        }

        /* Track scrollbar */
        ::-webkit-scrollbar-track {
          border-radius: 10px; /* Border radius untuk track */
        }

        /* Handle scrollbar */
        ::-webkit-scrollbar-thumb {
          background-color: #888; /* Warna handle */
          border-radius: 10px; /* Border radius untuk handle */
        }

        /* Ketika hover pada handle */
        ::-webkit-scrollbar-thumb:hover {
          background-color: #555; /* Warna handle saat hover */
        }
        `}
			</style>
		</div>
	)
}

// Bungkus App dengan Router agar useLocation dapat berfungsi
const AppWithRouter = () => (
	<Router>
		<App />
	</Router>
)

export default AppWithRouter
