import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FaUser, FaCog, FaChartBar } from 'react-icons/fa'
import { db } from '../firebase' // Pastikan path ini sesuai dengan lokasi firebase.js
import { Helmet } from 'react-helmet'

const Dashboard = () => {
	const [userCount, setUserCount] = useState(0)

	useEffect(() => {
		// Ambil data jumlah pengguna dari Firestore
		const fetchUserCount = async () => {
			try {
				const usersCollection = db.collection('users') // Koleksi users di Firestore
				const snapshot = await usersCollection.get()
				const count = snapshot.size // Menghitung jumlah dokumen
				setUserCount(count)
			} catch (error) {
				console.error('Error fetching user count:', error)
			}
		}

		fetchUserCount()
	}, [])

	return (
		<div className="p-8 mt-24">
			<h1 className="text-3xl font-bold mb-3 text-center">Dasbor Admin</h1>
			<p className="text-lg mb-4 text-center">
				Selamat datang di dasbor admin aibeecara! Berikut adalah ringkasan
				informasi dan statistik terbaru.
			</p>

			{/* Statistik */}
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
				<div className="bg-white p-6 shadow-md rounded-lg flex items-center space-x-4">
					<div className="text-2xl text-blue-500">
						<FaUser />
					</div>
					<div>
						<h3 className="text-xl font-semibold">Jumlah Pengguna</h3>
						<p className="text-gray-600">{userCount} pengguna terdaftar</p>
					</div>
				</div>
				<div className="bg-white p-6 shadow-md rounded-lg flex items-center space-x-4">
					<div className="text-2xl text-green-500">
						<FaChartBar />
					</div>
					<div>
						<h3 className="text-xl font-semibold">Statistik Pengguna</h3>
						<p className="text-gray-600">
							Melihat grafik perkembangan pengguna
						</p>
					</div>
				</div>
				<div className="bg-white p-6 shadow-md rounded-lg flex items-center space-x-4">
					<div className="text-2xl text-orange-500">
						<FaCog />
					</div>
					<div>
						<h3 className="text-xl font-semibold">Pengaturan Sistem</h3>
						<p className="text-gray-600">
							Kelola pengaturan sistem dan preferensi
						</p>
					</div>
				</div>
			</div>

			{/* Aksi Cepat */}
			<div className="bg-white p-6 shadow-md rounded-lg">
				<h2 className="text-2xl font-semibold mb-4">Aksi Cepat</h2>
				<div className="grid grid-cols-1 md:grid-cols-1 gap-4">
					<Link
						to="/users"
						className="bg-primary text-white py-2 px-4 rounded-md text-center hover:bg-secondary transition-colors"
					>
						Lihat Pengguna
					</Link>
					<Link
						to="/settings"
						className="bg-primary text-white py-2 px-4 rounded-md text-center hover:bg-secondary transition-colors"
					>
						Pengaturan Sistem
					</Link>
					<Link
						to="/reports"
						className="bg-primary text-white py-2 px-4 rounded-md text-center hover:bg-secondary transition-colors"
					>
						Lihat Laporan
					</Link>
					{/* <Link
						to="/analytics"
						className="bg-secondary text-white py-2 px-4 rounded-md text-center hover:bg-primary transition-colors"
					>
						Analisis Data
					</Link> */}
				</div>
			</div>

      <Helmet>
        <title>Dasbor Admin - aibeecara</title>
      </Helmet>
		</div>
	)
}

export default Dashboard
