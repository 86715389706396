import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import backgroundImage from '../assets/bg.jpeg'
import aibeecaraLogo from '../assets/IconAibeecaraOnly.png'
import { Helmet } from 'react-helmet'
import toast, { Toaster } from 'react-hot-toast'

const allowedEmail = 'adminaibee@aibeecara.id'
const allowedPassword = 'pintarAibee'

const Login = () => {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const navigate = useNavigate()

	useEffect(() => {
		const isLoggedIn = localStorage.getItem('isLoggedIn')
		if (isLoggedIn) {
			navigate('/dashboard', { replace: true })
		}
	}, [navigate])

	const handleSubmit = (e) => {
		e.preventDefault()

		if (email === allowedEmail && password === allowedPassword) {
			toast('Login berhasil!', {
				icon: '✅',
				duration: 2000,
			})

			localStorage.setItem('isLoggedIn', 'true')

			navigate('/dashboard', { replace: true })
		} else {
			toast.error('Email atau password salah!', {
				duration: 2000,
				icon: '❌',
			})
		}
	}

	return (
		<div
			className="flex items-center justify-center h-screen bg-gray-100"
			style={{
				backgroundImage: `url(${backgroundImage})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
			}}
		>
			<Toaster position="top-center" />
			<div className="w-full max-w-md p-8 bg-white shadow-md rounded-lg bg-opacity-90">
				<div className="flex justify-center mb-4">
					<img
						src={aibeecaraLogo}
						alt="Logo Aibeecara"
						className="w-16 h-16 object-cover"
					/>
				</div>
				<h2 className="text-2xl font-bold mb-6 text-center">Dashboard Admin</h2>
				<form onSubmit={handleSubmit}>
					<div className="mb-4">
						<label
							htmlFor="email"
							className="block text-sm font-medium text-gray-700"
						>
							Email
						</label>
						<input
							type="email"
							id="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required
							className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
						/>
					</div>
					<div className="mb-6">
						<label
							htmlFor="password"
							className="block text-sm font-medium text-gray-700"
						>
							Password
						</label>
						<input
							type="password"
							id="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							required
							className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
						/>
					</div>
					<button
						type="submit"
						className="w-full py-2 px-4 bg-primary text-white font-bold rounded-md shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
					>
						Login
					</button>
				</form>
			</div>
			<Helmet>
				<title>Login Admin - aibeecara</title>
			</Helmet>
		</div>
	)
}

export default Login
