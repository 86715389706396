import React from 'react'
import { motion } from 'framer-motion'
import { IoClose } from 'react-icons/io5'

const Modal = ({
	isOpen,
	onClose,
	onConfirm,
	title,
	children,
	showAction,
	firstText = 'Batal',
	secondText = 'Hapus',
}) => {
	if (!isOpen) return null

	return (
		<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
			{/* Animasi Modal */}
			<motion.div
				initial={{ opacity: 0, scale: 0.8 }}
				animate={{ opacity: 1, scale: 1 }}
				exit={{ opacity: 0, scale: 0.8 }}
				transition={{ duration: 0.3, ease: 'easeInOut' }}
				className="relative bg-white rounded-lg shadow-lg w-full max-w-lg md:max-w-3xl p-6"
			>
				{/* Close Button */}
				<button
					onClick={onClose}
					className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
				>
					<IoClose size={24} />
				</button>

				{/* Title */}
				<h2 className="text-2xl font-bold text-center text-gray-800 mb-4">
					{title}
				</h2>

				{/* Content */}
				<div className="text-gray-600">{children}</div>

				{/* Action Buttons */}
				{showAction && (
					<div className="flex justify-end mt-6 space-x-4">
						<button
							onClick={onClose}
							className="px-4 py-2 text-sm font-medium bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
						>
							{firstText}
						</button>
						<button
							onClick={onConfirm}
							className="px-4 py-2 text-sm font-medium bg-red-500 text-white rounded-md hover:bg-red-600"
						>
							{secondText}
						</button>
					</div>
				)}
			</motion.div>
		</div>
	)
}

export default Modal
