import React, { useEffect, useState } from 'react'
import { db } from '../firebase'
import { FaEye, FaTrash } from 'react-icons/fa'
import Modal from '../components/Modal'
import { Helmet } from 'react-helmet'
import { motion } from 'framer-motion'
import Swal from 'sweetalert2'

const Users = () => {
	const [users, setUsers] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState('')
	const [searchTerm, setSearchTerm] = useState('')
	const [selectedUser, setSelectedUser] = useState(null)
	const [showModal, setShowModal] = useState(false)
	const [showConfirmModal, setShowConfirmModal] = useState(false)
	const [userToDelete, setUserToDelete] = useState(null)
	const [userCount, setUserCount] = useState(0)

	useEffect(() => {
		const fetchUsers = async () => {
			try {
				const snapshot = await db.collection('users').get()
				if (snapshot.empty) {
					setError('No users found')
					return
				}

				setUserCount(snapshot.size)
				const usersList = snapshot.docs.map((doc) => {
					const data = doc.data()
					return {
						id: doc.id,
						name: data.displayName || 'No Name',
						email: data.email || 'No Email',
						photoURL: data.photoURL || '',
						inventory: data.inventory || {},
						category: data.category || 'GRATIS',
						loginHistory: [],
					}
				})

				setUsers(usersList.sort((a, b) => a.email.localeCompare(b.email)))
			} catch (err) {
				setError('Failed to fetch users')
				console.error(err)
			} finally {
				setLoading(false)
			}
		}

		fetchUsers()
	}, [])

	const fetchLoginHistory = async (userId) => {
		try {
			const snapshot = await db
				.collection('users')
				.doc(userId)
				.collection('loginHistory')
				.get()
			return snapshot.empty ? [] : snapshot.docs.map((doc) => doc.data())
		} catch (err) {
			console.error(err)
			return []
		}
	}

	const handleViewDetails = async (user) => {
		const loginHistory = await fetchLoginHistory(user.id)
		setSelectedUser({ ...user, loginHistory })
		setShowModal(true)
	}

	const handleDelete = (user) => {
		setUserToDelete(user)
		setShowConfirmModal(true)
	}

	const confirmDelete = async () => {
		if (userToDelete) {
			try {
				await db.collection('users').doc(userToDelete.id).delete()
				setUsers(users.filter((u) => u.id !== userToDelete.id))
			} catch (err) {
				console.error(err)
			} finally {
				setShowConfirmModal(false)
				setUserToDelete(null)
			}
		}
	}

	const handleCategoryChange = async (userId, newCategory) => {
		try {
			await db.collection('users').doc(userId).update({ category: newCategory })
			setUsers(
				users.map((user) =>
					user.id === userId ? { ...user, category: newCategory } : user
				)
			)
		} catch (err) {
			console.error('Error updating category:', err)
		}
	}

	if (loading) return <p className="text-center mt-20">Loading...</p>
	if (error) return <p className="text-red-500 text-center mt-20">{error}</p>

	const filteredUsers = users.filter(
		(user) =>
			user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
			user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
			user.id.toLowerCase().includes(searchTerm.toLowerCase())
	)

	const changeAllCategories = async (newCategory) => {
		try {
			// Konfirmasi menggunakan SweetAlert2
			const result = await Swal.fire({
				title: 'Konfirmasi Perubahan',
				text: `Apakah Anda yakin ingin mengubah semua kategori menjadi "${newCategory}"?`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Ubah Semua!',
				cancelButtonText: 'Batal',
			})

			if (result.isConfirmed) {
				// Batch update all users to the selected category
				const batch = db.batch()
				users.forEach((user) => {
					const userRef = db.collection('users').doc(user.id)
					batch.update(userRef, { category: newCategory })
				})
				await batch.commit()

				// Update the local state
				setUsers(
					users.map((user) => ({
						...user,
						category: newCategory,
					}))
				)

				// Beri notifikasi sukses
				await Swal.fire({
					title: 'Berhasil!',
					text: `Semua kategori telah diubah menjadi "${newCategory}".`,
					icon: 'success',
					confirmButtonColor: '#3085d6',
				})
			}
		} catch (err) {
			console.error('Error updating all categories:', err)
			Swal.fire({
				title: 'Error!',
				text: 'Terjadi kesalahan saat mengubah kategori.',
				icon: 'error',
				confirmButtonColor: '#d33',
			})
		}
	}

	return (
		<div className="p-8 mt-24">
			<Helmet>
				<title>Database Pengguna - aibeecara</title>
			</Helmet>
			<h1 className="text-3xl font-bold text-center mb-6">
				Database Pengguna Aibeecara
			</h1>
			<input
				type="text"
				placeholder="Cari pengguna..."
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
				className="w-full mb-4 px-3 py-2 border rounded-md shadow-sm focus:outline-none"
			/>
			<div className="bg-secondary text-white rounded-lg px-4 py-2 mb-4 font-bold text-center">
				Jumlah: {userCount} Pengguna
			</div>
			<div className="flex space-x-2 mb-4 w-full">
				<button
					onClick={() => changeAllCategories('GRATIS')}
					className="px-4 py-2 border-2 border-primary hover:bg-primary hover:text-white text-primary rounded-md w-full"
				>
					Ubah Semua ke GRATIS
				</button>
				<button
					onClick={() => changeAllCategories('PREMIUM 1')}
					className="px-4 py-2 border-2 border-secondary hover:bg-secondary hover:text-white text-secondary rounded-md w-full"
				>
					Ubah Semua ke PREMIUM 1
				</button>
				<button
					onClick={() => changeAllCategories('PREMIUM 2')}
					className="px-4 py-2 border-2 border-orange-700 hover:bg-orange-700 hover:text-white text-orange-700 rounded-md w-full"
				>
					Ubah Semua ke PREMIUM 2
				</button>
			</div>
			<div className="overflow-x-auto">
				<table className="min-w-full bg-white border border-gray-300 rounded-lg">
					<thead>
						<tr className="bg-primary text-white">
							<th className="py-2 px-4 border">No.</th>
							<th className="py-2 px-4 border">User ID</th>
							<th className="py-2 px-4 border">Nama</th>
							<th className="py-2 px-4 border">Email</th>
							<th className="py-2 px-4 border">Kategori</th>
							<th className="py-2 px-4 border">Aksi</th>
						</tr>
					</thead>
					<tbody>
						{filteredUsers.map((user, index) => (
							<tr key={user.id} className="border-b">
								<td className="py-2 px-4 border">{index + 1}</td>
								<td className="py-2 px-4 border">{user.id}</td>
								<td className="py-2 px-4 border">{user.name}</td>
								<td className="py-2 px-4 border">{user.email}</td>
								<td className="py-2 px-4 border">
									<select
										value={user.category}
										onChange={(e) =>
											handleCategoryChange(user.id, e.target.value)
										}
										className="px-2 py-1 border rounded"
									>
										<option value="GRATIS">GRATIS</option>
										<option value="PREMIUM 1">PREMIUM 1</option>
										<option value="PREMIUM 2">PREMIUM 2</option>
									</select>
								</td>
								<td className="py-2 px-4 border text-center">
									<button
										onClick={() => handleViewDetails(user)}
										className="text-blue-500 mx-2"
									>
										<FaEye />
									</button>
									<button
										onClick={() => handleDelete(user)}
										className="text-red-500 mx-2"
									>
										<FaTrash />
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>

			<Modal
				isOpen={showModal}
				onClose={() => setShowModal(false)}
				title="Detail Pengguna"
			>
				{selectedUser && (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
					>
						<div className="space-y-6">
							{/* Section 1: Informasi Umum */}
							<div className=" bg-white rounded-lg border border-gray-300 relative">
								<h3 className="font-semibold text-lg bg-primary rounded-t-lg flex items-center justify-center text-white py-1.5 px-3">
									Informasi Umum
								</h3>

								<div className="flex flex-row justify-between items-center p-4">
									<div>
										<p>
											<strong>Nama:</strong>{' '}
											{selectedUser.name || 'Tidak Ada Nama'}
										</p>
										<p>
											<strong>Email:</strong>{' '}
											{selectedUser.email || 'Tidak Ada Email'}
										</p>
										<p>
											<strong>Level Skill:</strong>{' '}
											{selectedUser.skillLevel || 'Tidak Ada Data'}
										</p>
										<p>
											<strong>Tanggal Dibuat:</strong>{' '}
											{selectedUser.timeCreated
												? new Date(selectedUser.timeCreated).toLocaleString(
														'en-US',
														{
															year: 'numeric',
															month: 'long',
															day: 'numeric',
															hour: 'numeric',
															minute: 'numeric',
															second: 'numeric',
															timeZoneName: 'short',
														}
												  )
												: 'Tidak Ada Data'}
										</p>
										<p>
											<strong>Login Terakhir:</strong>{' '}
											{selectedUser.timeLoggedIn
												? new Date(selectedUser.timeLoggedIn).toLocaleString(
														'en-US',
														{
															year: 'numeric',
															month: 'long',
															day: 'numeric',
															hour: 'numeric',
															minute: 'numeric',
															second: 'numeric',
															timeZoneName: 'short',
														}
												  )
												: 'Tidak Ada Data'}
										</p>
									</div>

									<div className="w-24 h-24 bg-gray-300 rounded-full overflow-hidden flex items-center justify-center">
										{selectedUser.photoURL ? (
											<img
												src={selectedUser.photoURL}
												alt="User Photo"
												className="object-cover w-full h-full"
											/>
										) : (
											<span className="text-gray-500">No Photo</span>
										)}
									</div>
								</div>
							</div>

							{/* Section 2: Kategori */}
							<div className="bg-white rounded-lg border border-gray-300">
								<h3 className="font-bold text-lg bg-primary flex items-center justify-center py-1.5 px-3 text-white rounded-t-lg">
									Kategori
								</h3>
								<div className="p-4">
									<p>
										<strong>Kategori:</strong>{' '}
										{selectedUser.category || 'Tidak Ada Kategori'}
									</p>
									<p>
										<strong>Tanggal Kadaluarsa:</strong>{' '}
										{selectedUser.expiryDate || 'N/A'}
									</p>
								</div>
							</div>
							{/* Section 3: Inventory */}
							<div className="bg-white rounded-lg border border-gray-300">
								<h3 className="font-bold text-lg bg-primary flex items-center justify-center px-3 py-1.5 text-white rounded-t-lg">
									Inventory
								</h3>
								<div className="grid grid-cols-2 gap-4 p-4">
									<div>
										<strong>Heart:</strong> {selectedUser.inventory.heart || 0}
									</div>
									<div>
										<strong>Honey:</strong> {selectedUser.inventory.honey || 0}
									</div>
									<div>
										<strong>Jar:</strong> {selectedUser.inventory.jar || 0}
									</div>
									<div>
										<strong>Vocabs:</strong>{' '}
										{selectedUser.inventory.vocabs || 0}
									</div>
								</div>
							</div>
						</div>
					</motion.div>
				)}
			</Modal>

			<Modal
				isOpen={showConfirmModal}
				onClose={() => setShowConfirmModal(false)}
				onConfirm={confirmDelete}
				title="Konfirmasi Hapus"
			>
				<p>Apakah Anda yakin ingin menghapus pengguna ini?</p>
			</Modal>
		</div>
	)
}

export default Users
