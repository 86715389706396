import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import notFoundImage from '../assets/errorPict.png' // Pastikan path sesuai dengan lokasi gambar

const ComingSoon = () => {
	return (
		<div className="flex items-center justify-center h-screen bg-gray-100">
			<div className="text-center p-6 bg-white shadow-md rounded-lg">
				<img
					src={notFoundImage}
					alt="404 Not Found"
					className="mx-auto mb-6"
					style={{ maxWidth: '100%', height: '100px' }} // Menyesuaikan ukuran gambar
				/>
				<h1 className="text-3xl font-bold mb-4">Segera Hadir</h1>
				<p className="text-lg mb-6">
					Halaman ini sedang dalam proses pengembangan.
				</p>
				<Link
					to="/dashboard"
					className="bg-primary text-white py-2 px-4 rounded-md hover:bg-secondary transition-colors"
				>
					Kembali ke Dashboard
				</Link>
			</div>

			<Helmet>
				<title>Segera Hadir - aibeecara</title>
			</Helmet>
		</div>
	)
}

export default ComingSoon
