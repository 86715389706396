import React, { useState } from 'react'
import { FaBars, FaTimes, FaSignOutAlt } from 'react-icons/fa'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { motion } from 'framer-motion'
import aibeecaraText from '../../assets/TextAibeecaraOnly.png'
import aibeecaraLogo from '../../assets/IconAibeecaraOnly.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Modal from '../Modal'

const itemVariants = {
	hidden: { opacity: 0, y: 20 },
	visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
}

const menuItems = {
	ADMIN: ['Dasbor', 'Pengguna', 'Laporan', 'Pengaturan'],
}

const paths = {
	ADMIN: ['dashboard', 'users', 'reports', 'settings'],
}

export default function NavbarMobile() {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const location = useLocation()
	const pathname = location.pathname
	const navigate = useNavigate()

	const menu = menuItems.ADMIN
	const pathsForMenu = paths.ADMIN

	const toggleMenu = () => {
		setIsOpen(!isOpen)
	}

	const handleLogout = () => {
		setIsModalOpen(true)
	}

	const confirmLogout = () => {
		setIsModalOpen(false)
		// Hapus data login dari localStorage
		localStorage.removeItem('isLoggedIn')
		// Arahkan ke halaman login
		navigate('/', { replace: true })
	}

	return (
		<>
			<motion.div
				className="rounded-[25px] fixed top-[24px] left-[24px] right-[24px] border-2 z-50 font-baloobhaijaan2 font-normal text-lg shadow-lg bg-white"
				initial={{ y: -100, opacity: 0 }}
				animate={{ y: 0, opacity: 1 }}
				transition={{ duration: 0.5 }}
			>
				<div className="flex justify-between items-center px-5 py-4">
					<a href="/" className="flex items-center gap-3">
						<LazyLoadImage
							src={aibeecaraLogo}
							alt="logo-beecara"
							className="w-8 cursor-pointer"
						/>
						<LazyLoadImage
							src={aibeecaraText}
							alt="logo-beecara"
							className="w-28 cursor-pointer"
						/>
					</a>
					<button onClick={toggleMenu} className="text-black">
						{isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
					</button>
				</div>
			</motion.div>

			{isOpen && (
				<motion.div
					style={{ backgroundColor: '#ffffff' }}
					className="fixed top-0 left-0 right-0 bottom-0 z-40 font-baloobhaijaan2 font-normal text-lg shadow-lg"
					initial={{ y: -100, opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ duration: 0.5 }}
				>
					<ul className="flex flex-col items-center justify-center h-full space-y-8">
						{menu.map((item, index) => {
							const itemPath = `/${pathsForMenu[index]}`
							return (
								<motion.li
									key={item}
									initial="hidden"
									animate="visible"
									variants={itemVariants}
								>
									<Link
										to={itemPath}
										className={`px-4 py-2 font-semibold transition-all hover:font-semibold ${
											pathname.startsWith(itemPath)
												? 'bg-gradient-to-r from-[#FFB526] to-[#FF8E26] text-white font-semibold rounded-full'
												: ''
										}`}
										onClick={toggleMenu}
									>
										{item}
									</Link>
								</motion.li>
							)
						})}
						<li>
							<button
								onClick={handleLogout}
								className="flex items-center gap-2 px-4 py-2 font-semibold transition-all hover:font-semibold bg-red-500 text-white rounded-full"
							>
								<FaSignOutAlt size={20} />
								Logout
							</button>
						</li>
					</ul>
				</motion.div>
			)}

			<Modal
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				onConfirm={confirmLogout}
				title="Konfirmasi Logout"
				showAction={true}
				firstText="Batal"
				secondText="Keluar"
			>
				<p>Apakah Anda yakin ingin keluar?</p>
			</Modal>
		</>
	)
}
