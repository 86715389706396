import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import NavbarDesktop from './NavBar/Desktop'
import NavbarMobile from './NavBar/Mobile'

export default function Navbar() {
	const [isMenuOpen, setMenuOpen] = useState(false)
	const isMobile = useMediaQuery({ maxWidth: 767 })

	const handleMenuToggle = () => {
		setMenuOpen(!isMenuOpen)
	}

	const language = 'ID' // Hardcoded for Indonesian

	return (
		<>
			{isMobile ? (
				<NavbarMobile
					isOpen={isMenuOpen} // Ensure this is passed correctly
					onToggle={handleMenuToggle} // Ensure this is passed correctly
					language={language} // Ensure this is passed correctly
				/>
			) : (
				<NavbarDesktop language={language} />
			)}
		</>
	)
}
