import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'; // Import Firestore

const firebaseConfig = {
  apiKey: 'AIzaSyDy4EwxDKYwp7VQ4w16h9VDHL7h6FzMbvo',
  authDomain: 'aibeecara-firebase.firebaseapp.com',
  projectId: 'aibeecara-firebase',
  storageBucket: 'aibeecara-firebase.appspot.com',
  messagingSenderId: '924114369741',
  appId: '1:924114369741:web:8ba039643ddb4af2357d84',
  measurementId: 'G-CGW546YEQ6',
};

const app = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore(app); // Initialize Firestore

export { db };
