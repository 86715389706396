import React, { useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { motion } from 'framer-motion'
import { useLocation, useNavigate } from 'react-router-dom'
import { FiLogOut } from 'react-icons/fi'
import aibeecaraText from '../../assets/TextAibeecaraOnly.png'
import aibeecaraLogo from '../../assets/IconAibeecaraOnly.png'
import Modal from '../Modal'

const itemVariants = {
	hidden: { opacity: 0, y: 20 },
	visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
}

const menuItems = {
	ADMIN: ['Dasbor', 'Pengguna', 'Laporan', 'Pengaturan'],
}

const paths = {
	ADMIN: ['dashboard', 'users', 'reports', 'settings'],
}

export default function NavbarDesktop() {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const location = useLocation()
	const navigate = useNavigate() // Hook untuk navigasi
	const pathname = location.pathname

	const menu = menuItems.ADMIN
	const pathsForMenu = paths.ADMIN

	const handleLogout = () => {
		setIsModalOpen(true)
	}

	const confirmLogout = () => {
		setIsModalOpen(false)
		// Hapus data login dari localStorage
		localStorage.removeItem('isLoggedIn')
		// Arahkan ke halaman login
		navigate('/', { replace: true })
	}

	return (
		<>
			<motion.div
				style={{ backgroundColor: '#ffffff' }}
				className="rounded-[25px] fixed top-[24px] left-[24px] right-[24px] border-2 z-50 font-baloobhaijaan2 font-normal text-lg shadow-lg"
				initial={{ y: -100, opacity: 0 }}
				animate={{ y: 0, opacity: 1 }}
				transition={{ duration: 0.5 }}
			>
				<div className="flex justify-between items-center px-5 py-4">
					<a href="/" className="flex flex-row gap-3 items-center">
						<LazyLoadImage
							src={aibeecaraLogo}
							alt="logo-beecara"
							className="w-8 cursor-pointer"
						/>
						<LazyLoadImage
							src={aibeecaraText}
							alt="logo-beecara"
							className="w-28 cursor-pointer"
						/>
					</a>
					<ul className="flex items-center space-x-8 justify-end flex-1">
						{menu.map((item, index) => {
							const itemPath = `/${pathsForMenu[index]}`
							return (
								<motion.li
									key={item}
									initial="hidden"
									animate="visible"
									variants={itemVariants}
								>
									<a
										href={itemPath}
										className={`transition-all hover:font-semibold hover:cursor-pointer ${
											pathname.startsWith(itemPath)
												? 'bg-gradient-to-r from-[#FFB526] to-[#FF8E26] rounded-full px-4 py-2 text-white font-semibold'
												: ''
										}`}
									>
										{item}
									</a>
								</motion.li>
							)
						})}
						<motion.li initial="hidden" animate="visible" variants={itemVariants}>
							<button
								onClick={handleLogout}
								className="flex items-center gap-2 transition-all hover:font-semibold hover:cursor-pointer bg-red-500 rounded-full px-4 py-2 text-white font-semibold"
							>
								<FiLogOut />
								Keluar
							</button>
						</motion.li>
					</ul>
				</div>
			</motion.div>
			<Modal
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				onConfirm={confirmLogout}
				title="Konfirmasi Logout"
				showAction={true}
				firstText="Batal"
				secondText="Keluar"
			>
				<p>Apakah Anda yakin ingin keluar?</p>
			</Modal>
		</>
	)
}
